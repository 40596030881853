import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import payment from "./payment.module";
import report from "./report.module";
import service from "./service.module";
import tuVan from "./tuVan.module";

import permission from "./user/permission.module";
import user from "./user/users.module";
import accountType from "./user/account-type.module";
import role from "./user/role.module";
import customer from "./customer/customer.module";
import notification from "./notification.module";
import business_level from "./business/level.module";
import course from "./course/course.module";
import contractOnline from "./contract/contractOnline.module";
import testInputModule from "@/core/services/store/customer/testInput.module";
import school from "./contract/school.module";

//public
import province from "./public/provinces.module";
import district from "./public/districts.module";
import contract from "./contract/contract.module";

/*center*/
import branch from './center/branch.module';
import center from './center/center.module';
// Course
import courseLevel from './course/courseLevel.module';
/* advise */
import advise from './advise.module';
import finances from './finances/finances.module'
import telegram from "./telegram/telegram.module";
import kpi from './kpi/kpi.module'
import pancake from './user/pancake.module'
Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        payment,
        service,
        report,
        permission,
        user,
        accountType,
        role,
        tuVan,
        customer,
        branch,
        contract,
        center,
        notification,
        advise,
        province,
        district,
        courseLevel,
        business_level,
        course,
        contractOnline,
        testInputModule,
        school,
        kpi,
        finances,
        telegram,
        pancake
    }
});
export default store;
