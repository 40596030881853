import ApiService from "@/core/services/api.service";
const _ = require('lodash');

export const GET_DATA_KPI_PERSIONAL = "kraMonthlyResults";
export const SHOW_DATA_KPI_PERSIONAL = "kraManager/kraAndMeetingByUser";
export const GET_KRA_METTING = "kra-manager/weekly-meeting-criteria";
export const UPDATE_DATA_KPI_TEAM_LEAD = "kraManager/details/update/team-lead";
export const UPDATE_DATA_KPI_PERSIONAL = "kraManager/details/update/persional";
export const GET_DATA_DETAIL_KPI = "kraManager/details";

const state = {
    errors: null,
};

const getters = {};

const actions = {
    [GET_DATA_KPI_PERSIONAL](context, payload) {
        return new Promise((resolve) => {
            ApiService.query(GET_DATA_KPI_PERSIONAL, payload).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },

    [GET_DATA_DETAIL_KPI](context, payload) {
        return new Promise((resolve) => {
            ApiService.query(GET_DATA_DETAIL_KPI, payload).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },

    [GET_KRA_METTING](context, payload) {
        return new Promise((resolve) => {
            ApiService.query(GET_KRA_METTING, payload).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },

    [SHOW_DATA_KPI_PERSIONAL](context, payload) {
        return new Promise((resolve) => {
            ApiService.query(SHOW_DATA_KPI_PERSIONAL, payload).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },

    [UPDATE_DATA_KPI_PERSIONAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(UPDATE_DATA_KPI_PERSIONAL, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [UPDATE_DATA_KPI_TEAM_LEAD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(UPDATE_DATA_KPI_TEAM_LEAD, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
}

const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};