import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_BOT_USER = "telegram/telegram-bot-user";
export const ACTION_BOT_USER = "telegram/telegram-bot-user/action";

const state = {};
const getters = {};
const actions = {
    // Get provinces
    [GET_BOT_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_BOT_USER, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
                console.log(response)
            });
        })
    },
    [ACTION_BOT_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(ACTION_BOT_USER, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
