import ApiService from "@/core/services/api.service";

const _ = require('lodash');

export const GET_USER_PANCAKE = "user-pancake/get";
export const ADD_USER_PANCAKE = "user-pancake/add";
export const CANCEL_USER_PANCAKE = "user-pancake/cancel";


const state = {};
const getters = {};
const actions = {
    [GET_USER_PANCAKE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_USER_PANCAKE, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [ADD_USER_PANCAKE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${ADD_USER_PANCAKE}`, payload)
                .then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [CANCEL_USER_PANCAKE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CANCEL_USER_PANCAKE, payload)
                .then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                reject(response);
            });
        });
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
