// action types
import ApiService from "../api.service";
import {SET_ERROR} from "./auth.module";

export const POST_VERIFY_IDENTITY = process.env.VUE_APP_API_URL + '/profile/identity';
export const UPDATE_PROFILE_AFTER_DISMISS_ACTIVE = 'update-profile-after-dismiss-or-active';
export const USER_PROFILE = "profile";
export const USER_UPDATE = "update";
export const USER_UPDATE_BANK = "update-bank";
export const USER_VALIDATE_PHONE = "validate-phone";
export const USER_SEND_OTP_BY_PHONE = "profile/send-otp";
export const USER_VERIFY_PHONE = "verify-phone";
export const USER_UPLOAD_IMAGE = "upload-avatar";
export const USER_CHANGE_PASSWORD = "change-password";
export const USER_UPLOAD_IDENTITY = "identity";
export const USER_REQUEST_IDENTITY = "request-infor-identity";
export const USER_HUMAN_RESOURCE = "profile/get-human-resource";
export const UPDATE_HUMAN_RESOURCE = "profile/update-human";
export const UPDATE_DISMISSAL_ACTIVE = "profile/dismissal-active";
export const REJECT_USER = "profile/reject-user-waiting";
export const REJECT_USER_IDENTITY = "profile/reject-user-identity";
export const ACCEPT_USER = "profile/accept-user-waiting";
export const ACCEPT_USER_IDENTITY = "profile/accept-user-identity";
export const GET_USER_BY_ID = "get-user-by-id";
export const SALE_UPDATE_PROFILE = "profile/sale-update-profile";
export const SALE_CHECK_HAPPY_LEVEL_UP = "profile/sale_check_happy_level_up";
export const SALE_UPDATE_HAPPY_LEVEL_UP = "profile/sale-update-happy-level-up";
export const EXPORT_EXCEL = "profile/export-human-resource";
export const USER_DASHBOARD = "profile/dashboard";
export const COUNT_IS_WAIT_USER = "profile/get-number-wait";
export const UPDATE_QPV = "profile/update-qpv";
export const PROFILE_UPDATE_BIZFLY_ID = "profile_update_bizfly_id";

// mutation types
export const SET_USER_PROFILE = "setProfile";
export const SET_AVATAR = "setAvatar";
export const SET_NUMBER_HUMAN_RESOURCE = "set_human_resources";
export const UPDATE_NUMBER_HUMAN_RESOURCE = "update_human_resources";

export const GET_DEPARTMENT = "profile/get-department";
export const GET_DEPARTMENT_BY_DEPARTMENT_HEAD = "profile/get-department-by-department-head";
export const GET_DEPARTMENT_BY_ID = "profile/get-department-by-id";

const state = {
    isWaitUsers: localStorage.getItem(`isWaitUsers`) || 0
};

const getters = {
    getCountWaitUser(state) {
        return state.isWaitUsers;
    }
};

const actions = {
    [USER_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/profile/update`, payload)
                .then(({data}) => {
                    context.commit(SET_USER_PROFILE, data);
                    resolve(data);
                })
                .catch((res) => {
                    reject(res.response);
                });
        });
    },

    [USER_PROFILE](context) {
        return new Promise(resolve => {
            ApiService.query(`/${USER_PROFILE}`)
                .then(({data}) => {
                    context.commit(SET_USER_PROFILE, data);
                    resolve(data);
                })
        });
    },
    [GET_DEPARTMENT](context) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_DEPARTMENT}`)
                .then(({ data }) => {
                    resolve(data);
                })
        });
    },

    [GET_DEPARTMENT_BY_DEPARTMENT_HEAD](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_DEPARTMENT_BY_DEPARTMENT_HEAD}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
        });
    },
    [USER_UPDATE_BANK](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/profile/${USER_UPDATE_BANK}`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            });
        });
    },

    [USER_VALIDATE_PHONE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/profile/${USER_VALIDATE_PHONE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [USER_VERIFY_PHONE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/profile/${USER_VERIFY_PHONE}`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [USER_UPLOAD_IMAGE](context, payload) {
        return new Promise(resolve => {
            ApiService.upload(`/profile/${USER_UPLOAD_IMAGE}`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [PROFILE_UPDATE_BIZFLY_ID](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/profile/update-bizfly-id`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                reject(response);
            });
        });
    },
    [USER_UPLOAD_IDENTITY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.upload(`/profile/${USER_UPLOAD_IDENTITY}`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [USER_REQUEST_IDENTITY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/profile/${USER_REQUEST_IDENTITY}`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [USER_CHANGE_PASSWORD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/profile/${USER_CHANGE_PASSWORD}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [UPDATE_HUMAN_RESOURCE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_HUMAN_RESOURCE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                // context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                reject(response);
            });
        });
    },
    [UPDATE_DISMISSAL_ACTIVE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_DISMISSAL_ACTIVE}/${payload.id}`, payload).then(({data}) => {
                context.commit(UPDATE_PROFILE_AFTER_DISMISS_ACTIVE, payload.id, data)
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [USER_HUMAN_RESOURCE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${USER_HUMAN_RESOURCE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    /**
     * Số lượng nhân sự chờ
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [COUNT_IS_WAIT_USER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${COUNT_IS_WAIT_USER}`, payload).then(({data}) => {
                if (payload.is_update) {
                    context.commit(UPDATE_NUMBER_HUMAN_RESOURCE, data.data)
                } else {
                    context.commit(SET_NUMBER_HUMAN_RESOURCE, data.data)
                }
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [REJECT_USER](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(`${REJECT_USER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [REJECT_USER_IDENTITY](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(`${REJECT_USER_IDENTITY}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },

    [ACCEPT_USER](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(`${ACCEPT_USER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [ACCEPT_USER_IDENTITY](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(`${ACCEPT_USER_IDENTITY}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [UPDATE_QPV](context, payload) {
        const {id, ...params} = payload;
        return new Promise((resolve) => {
            ApiService.post(`${UPDATE_QPV}/${id}`, params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [GET_USER_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('profile/show', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_DEPARTMENT_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_DEPARTMENT_BY_ID, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [SALE_UPDATE_PROFILE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${SALE_UPDATE_PROFILE}/${payload.id}`, payload.formData).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [SALE_CHECK_HAPPY_LEVEL_UP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${SALE_CHECK_HAPPY_LEVEL_UP}/${payload.id}`, payload.formData).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [SALE_UPDATE_HAPPY_LEVEL_UP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${SALE_UPDATE_HAPPY_LEVEL_UP}/${payload.id}`, payload.formData).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [EXPORT_EXCEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${EXPORT_EXCEL}`, payload).then(({data}) => {
                let filename = "danh-sach-nhan-su.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [USER_DASHBOARD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${USER_DASHBOARD}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [USER_SEND_OTP_BY_PHONE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${USER_SEND_OTP_BY_PHONE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USER_PROFILE](state, user) {
        // let userState = this.state.auth.user;
        // console.log(userState);
        this.state.auth.user = {...this.state.auth.user, ...user.data};
        localStorage.user = JSON.stringify(this.state.auth.user);
    },
    [SET_AVATAR](state, user) {
        this.state.auth.user.image = user.data.image;
        localStorage.user = JSON.stringify(this.state.auth.user);
    },
    [UPDATE_PROFILE_AFTER_DISMISS_ACTIVE](state, user) {
        let userState = this.state.auth.user;
        state.user = {...userState, ...user.data};
        localStorage.user = JSON.stringify(state.user);
    },

    [SET_NUMBER_HUMAN_RESOURCE](state, numberUsers) {
        state.isWaitUsers = numberUsers;
        localStorage.setItem('isWaitUsers', JSON.stringify(numberUsers));
    },

    [UPDATE_NUMBER_HUMAN_RESOURCE](state, numberUsers) {
        state.isWaitUsers = numberUsers;
        localStorage.isWaitUsers = JSON.stringify(numberUsers);
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
