import Vue from "vue";
import Router from "vue-router";
import {PERMISSION} from "./permission";
import {TPNS, TRAINER, TVV} from "@/core/config/accountTypeOption";

Vue.use(Router);
const routes = [
    {
        path: "/",
        redirect: "/dashboard",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/views/pages/Dashboard"),
            },
            {
                path: "/verifile-user",
                name: "verifile-user",
                component: () => import("@/views/pages/VerifileTelegramUser"),
            },
            {
                name: "identity",
                path: "/identity",
                component: () => import("@/views/pages/auth/Identity"),
            },
            {
                path: "/profile",
                name: "profile",
                component: () => import("@/views/pages/user/edutalk-profile"),
            },
            {
                path: "/payment-list",
                name: "payment-list",
                meta: {authorize: PERMISSION.DONG_TIEN_THEO_DOI_KD},
                component: () => import("@/views/pages/payment/edutalk-payment-list"),
            },
            {
                path: "/notification/all",
                name: "notification-all",
                component: () => import("@/views/pages/notification/NotificationAll"),
            },
            {
                path: "/payment-location",
                name: "payment-location",
                meta: {authorize: PERMISSION.DONG_TIEN_TAI_DIEM},
                component: () => import("@/views/pages/payment/edutalk-payment-location"),
            },
            {
                path: "/hierarchy",
                name: "hierarchy",
                meta: {authorize: PERMISSION.PHAN_CAP_NHAN_SU},
                component: () => import("@/views/pages/edutalk/edutalk-hierarchy"),
            },
            {
                path: "/nhan-su-hoat-dong",
                name: "nhan-su-hoat-dong",
                meta: {authorize: PERMISSION.NHAN_SU_HOAT_DONG, position: TPNS},
                component: () => import("@/views/pages/human-resource/nhan-su-hoat-dong"),
            },
            {
                path: "/nhan-su-cho-duyet",
                name: "nhan-su-cho-duyet",
                meta: {authorize: PERMISSION.NHAN_SU_CHO_XET_DUYET, position: TPNS},
                component: () => import("@/views/pages/human-resource/nhan-su-cho-duyet"),
            },
            {
                path: "/danh-sach-ung-vien",
                name: "danh-sach-ung-vien",
                meta: {authorize: PERMISSION.PHAN_CAP_NHAN_SU, position: TRAINER},
                component: () => import("@/views/pages/human-resource/danh-sach-ung-vien"),
            },

            // Report
            {
                path: "/report/human-resource",
                name: "nhan-su-trong-thang",
                meta: {authorize: PERMISSION.NHAN_SU_TRONG_THANG, position: TPNS},
                component: () => import("@/views/pages/report/human-resource"),
            },
            {
                path: "/report/kinh-doanh-doi",
                name: "kinh-doanh-doi",
                meta: {authorize: PERMISSION.KINH_DOANH_DOI},
                component: () => import("@/views/pages/report/kinh-doanh-doi-v2"),
            },
            {
                path: "/report/kinh-doanh-van-phong",
                name: "kinh-doanh-van-phong",
                meta: {authorize: PERMISSION.KINH_DOANH_VAN_PHONG},
                component: () => import("@/views/pages/report/kinh-doanh-van-phong"),
            },

            //Phieu Luong
            {
                path: "/phieu-luong",
                name: "phieu-luong",
                meta: {authorize: PERMISSION.PHIEU_LUONG},
                component: () => import("@/views/pages/human-resource/phieu-luong"),
            },
            {
                path: "/cham-cong",
                name: "cham-cong",
                meta: {authorize: PERMISSION.CHAM_CONG_VAN_TAY},
                component: () => import("@/views/pages/human-resource/cham-cong-nhan-su"),
            },
            {
                path: "/thu-nhap-van-phong",
                name: "thu-nhap-van-phong",
                meta: {authorize: PERMISSION.THU_NHAP_VAN_PHONG},
                component: () => import("@/views/pages/human-resource/thu-nhap-van-phong"),
            },
            //Tư vấn

            {
                path: "/advise/list-customer-tvv",
                name: "list-customer-tvv",
                meta: {authorize: PERMISSION.KHACH_HANG_TU_VAN, position: TVV},
                component: () => import("@/views/pages/advise/khach-hang-tu-van"),
            },

            {
                path: "/advise/doanh-so-tu-van",
                name: "doanh-so-tu-van",
                meta: {authorize: PERMISSION.DOANH_SO_TU_VAN, position: TVV},
                component: () => import("@/views/pages/advise/doanh-so-tu-van"),
            },
            {
                path: "/advise/list-customer-tvv/detail-customer/:id",
                name: "detail-customer",
                meta: {authorize: PERMISSION.KHACH_HANG_TU_VAN, position: TVV},
                component: () => import("@/views/pages/advise/detail-customer"),
            },

            // End Report
            {
                path: "/work-calendar",
                name: "work-calendar",
                component: () => import("@/views/pages/work/edutalk-register-work"),
            },
            {
                path: "/attendance-calendar",
                name: "attendance-calendar",
                component: () => import("@/views/pages/work/edutalk-attendance"),
            }, {
                path: "/attendance-view",
                name: "attendance-view",
                component: () => import("@/views/pages/work/attendance-view"),
            },
            {
                path: "/advise/center",
                name: "advise-center",
                component: () => import("@/views/pages/advise/center/AdviseCenter"),
            },
            {
                path: "/advise/courser",
                name: "advise-course",
                component: () => import("@/views/pages/advise/course/AdviseCourse"),
            },
            {
                path: "/detail-center/:center_id",
                name: "detail-center",
                component: () => import("@/views/pages/advise/center/DetailCenter"),
            },
            {
                path: "advise/detail-course/:course_id",
                name: "advise-detail-course",
                component: () => import("@/views/pages/advise/course/DetailCourse"),
            },
            {
                path: "advise/detail-classroom/:class_id",
                name: "advise-detail-detail-classroom",
                component: () => import("@/views/pages/advise/course/DetailClassroom"),
            },
            {
                path: "advise/detail-course-combo/:promotion_id",
                name: "advise-detail-course-combo",
                component: () => import("@/views/pages/advise/course/DetailCourseCombo"),
            },
            {
                path: "/sale/contracts",
                name: "sale-contracts",
                component: () => import("@/views/pages/contract/ContractIndex"),
            },
            {
                path: "/sale/installment-information/:contract_id/user/:user_id",
                name: "input-installment-information",
                component: () => import("@/views/pages/sale/InstallmentInformation"),
            },

            // End lịch làm

            // Start Đăng ký lịch trực
            {
                path: "/schedule_duty",
                name: "schedule_duty",
                meta: {authorize: PERMISSION.DANG_KI_LICH_TRUC, position: TVV},
                component: () => import("@/views/pages/work/edutalk-lich-truc"),
            },
            // End lịch trực

            //  Lịch tư vấn
            {
                path: "/lich-tuvan",
                name: "lich_tuvan",
                component: () => import("@/views/pages/work/edutalk-lich-tu-van"),
            },
            //  Phiếu đăng ký học tập edutalk
            {
                path: "/register-study",
                name: "register-study",
                meta: {authorize: PERMISSION.LICH_TU_VAN, position: TVV},
                component: () => import("@/views/pages/customer/RegisterStudy"),
            },
            //  Phiếu đăng ký học tập nhãn đối tác
            {
                path: "/register-study-partner",
                name: "register-study-partner",
                meta: {authorize: PERMISSION.LICH_TU_VAN, position: TVV},
                component: () => import("@/views/pages/customer/RegisterStudyPartner"),
            },
            // Danh sách đơn hàng
            {
                path: "/contract",
                name: "contract-index",
                meta: {authorize: PERMISSION.LICH_TU_VAN, position: TVV},
                component: () => import("@/views/pages/contract/ContractIndex"),
            },
            //Edit contract edutalk
            {
                path: "/contract/edit-edutalk/:contract_id",
                name: "contract-edit-edutalk",
                meta: {authorize: PERMISSION.LICH_TU_VAN, position: TVV},
                component: () => import("@/views/pages/customer/EditContractEdutalk"),
            },
            //Show contract edutalk
            {
                path: "/contract/show-edutalk/:contract_id",
                name: "contract-show-edutalk",
                meta: {authorize: PERMISSION.LICH_TU_VAN, position: TVV},
                component: () => import("@/views/pages/customer/ShowContractEdutalk"),
            },
            //Show contract partner
            {
                path: "/contract/show-partner/:contract_id",
                name: "contract-show-partner",
                meta: {authorize: PERMISSION.LICH_TU_VAN, position: TVV},
                component: () => import("@/views/pages/customer/ShowContractPartner"),
            },
            {
                path: "/contract/edit-partner/:contract_id",
                name: "contract-edit-partner",
                meta: {authorize: PERMISSION.LICH_TU_VAN, position: TVV},
                component: () => import("@/views/pages/customer/EditContractPartner"),
            },
            // Hệ thống thông tin
            {
                path: "/thong-tin/he-thong",
                name: "information-system",
                meta: {authorize: PERMISSION.HE_THONG_THONG_TIN},
                component: () => import("@/views/pages/information/system"),
            },
            {
                path: "/thong-tin/thong-bao",
                name: "information-notification",
                meta: {authorize: PERMISSION.THONG_BAO_TU_EDUTALK},
                component: () => import("@/views/pages/information/thong-bao"),
            },
            {
                path: "/thong-tin/thong-bao/:slug",
                name: "information-notification-slug",
                meta: {authorize: PERMISSION.THONG_BAO_TU_EDUTALK},
                component: () => import("@/views/pages/information/thong-bao-detail"),
            },
            {
                path: "/thong-tin/chinh-sach-nhan-su",
                name: "information-chinh-sach",
                meta: {authorize: PERMISSION.CHINH_SACH_NHAN_SU},
                component: () => import("@/views/pages/information/chinh-sach"),
            },
            {
                path: "/thong-tin/cam-nang-van-hanh",
                name: "information-cam-nang",
                meta: {authorize: PERMISSION.CAM_NANG_VAN_HANH},
                component: () => import("@/views/pages/information/cam-nang"),
            },
            // End hệ thống thông tin
            // Khách hàng
            {
                path: "/customer/list",
                name: "customer-list",
                meta: {authorize: PERMISSION.KHACH_HANG},
                component: () => import("@/views/pages/customer/edutalk-customer-list"),
            },
            {
                path: "/customer/create",
                name: "customer-create",
                component: () => import("@/views/pages/customer/edutalk-customer-form"),
            },
            {
                path: "/customer/view/:id",
                name: "customer-view",
                component: () => import("@/views/pages/advise/detail-customer"),
            },
            {
                path: "/customer/edit/:id",
                name: "customer-edit",
                component: () => import("@/views/pages/customer/edutalk-customer-form"),
            },
            {
                path: "/customer/result",
                name: "customer-result",
                component: () => import("@/views/pages/customer/dat-lich-dong-tien"),
            },
            {
                path: "/customer/test-input-schedule/:id",
                name: "customer-test-input-schedule",
                component: () => import("@/views/pages/customer/detail-test-input-schedule"),
            },
            {
                path: "/404",
                name: "404",
                component: () => import("@/views/error/error"),
            },
            {
                path: "/customer/re-register/:id/:re",
                name: "customer-re-register",
                component: () => import("@/views/pages/customer/edutalk-customer-form"),
            },
            {
                name: "tai-chinh",
                path: "/tai-chinh",
                component: () => import("@/views/layout/NoLayout.vue"),
                children: [
                    {
                        name: "bao-cao-van-phong",
                        path: "bao-cao-van-phong",
                        component: () => import("@/views/pages/financial/OfficeReport.vue"),
                    },
                    {
                        name: "bao-cao-khu-vuc",
                        path: "bao-cao-khu-vuc",
                        component: () => import("@/views/pages/financial/AreaReports.vue"),
                    },
                    {
                        name: "bao-cao-vung",
                        path: "bao-cao-vung",
                        component: () => import("@/views/pages/financial/RegionalReports.vue"),
                    },
                    {
                        name: "toc-do-tang-truong",
                        path: "toc-do-tang-truong",
                        component: () => import("@/views/pages/financial/GrowthRateReport.vue"),
                    },
                    {
                        name: "toc-do-tang-truong-doanh-so-khu-vuc",
                        path: "toc-do-tang-truong-doanh-so-khu-vuc",
                        component: () => import("@/views/pages/financial/GrowthRateSalesReport.vue"),
                    },
                ],
            },
            {
                name: "management-bots",
                path: "/management-bots",
                component: () => import("@/views/layout/NoLayout.vue"),
                children: [
                    {
                        name: "management-bots-list",
                        path: "/",
                        component: () => import("@/views/pages/management-bots/ManagementBotsIndex.vue"),
                    },
                ],
            },
        ]
    },
    {
        path: "/",
        component: () => import("@/views/pages/auth/Login"),
        children: [
            {
                name: "login",
                path: "/login",
                component: () => import("@/views/pages/auth/Login"),
            }
        ],
    },
    {
        path: "/",
        component: () => import("@/views/pages/auth/Register"),
        children: [
            {
                name: "register",
                path: "/register",
                component: () => import("@/views/pages/auth/Register"),
            }
        ],
    },
    {
        path: "/",
        component: () => import("@/views/pages/auth/Forgot-Password"),
        children: [
            {
                name: "forgot-password",
                path: "/forgot-password",
                component: () => import("@/views/pages/auth/Forgot-Password"),
            }
        ],
    },
    // học tập
    {
        path: "/",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                name: "quantum-leap-intro",
                path: "/quantum-leap-intro",
                component: () => import("@/views/pages/study/QuantumLeapIntro"),
            }
        ],
    },
    // đề xuất đơn
    {
        path: "/",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                name: "list-propose-application",
                path: "/propose",
                meta: {authorize: PERMISSION.THONG_TIN},
                component: () => import("@/views/pages/propose/ListProposeApplication"),
            },
            {
                name: "list-propose-lead-bod",
                path: "/propose/decided/teamLead-BOD",
                meta: {authorize: PERMISSION.DUYET_DE_XUAT},
                component: () => import("@/views/pages/propose/ListProposeApplicationLeadBod"),
            },
        ],
    },
    {
        path: "/",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                name: "job-kpi-staff",
                path: "/job-kpi/staff",
                meta: {authorize: PERMISSION.THONG_TIN},
                component: () => import("@/views/pages/job-kpi/Staff/JobKpiStaff"),
            },
            {
                name: "score-meeting-kpi",
                path: "/job-kpi/teamlead",
                meta: {authorize: PERMISSION.THONG_TIN},
                component: () => import("@/views/pages/job-kpi/TeamLead/JobKpiTeamlead"),
            },
        ],
    },
    {
        path: "/",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                name: "list-course",
                path: "/list-course",
                component: () => import("@/views/pages/study/ListCourse"),
            },
            {
                name: "training-manage",
                path: "/training-manage",
                component: () => import("@/views/pages/study/TrainingManage"),
            }
        ],
    },

     {
        path: "/",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                name: "manage-list-course",
                path: "/manage-list-course",
                meta: {authorize: PERMISSION.BAO_CAO_HOC_TAP, position: TRAINER},
                component: () => import("@/views/pages/mannage-list-course/ManageListCourse"),
            },
        ],
    },
    {
        name: "update-user",
        path: "/update-user/:id",
        component: () => import("@/views/pages/auth/XacMinhDanhTinh"),
    },
    {
        name: "maintain",
        path: "/maintain",
        component: () => import("@/views/pages/Maintain"),
    },
    {
        name: "feedback-email",
        path: "/feedback-email",
        component: () => import("@/views/pages/propose/FeedbackEmailProposes"),
    },
    {
        name: "honors",
        path: "/honors",
        component: () => import("@/views/layout/NoLayout.vue"),
        children: [
            {
                name: "honor-private",
                path: "/honors-page",
                component: () => import("@/views/pages/honors/HonorPage.vue"),
            },
            {
                name: "honor-detail",
                path: "/honor-detail/:id",
                component: () => import("@/views/pages/honors/HonorDetailPage.vue"),
            },
        ],
    },
];

const router = new Router({
    mode: "history",
    routes,
});
router.onError(error => {

    if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
        window.location.reload()
    }
}); // onError

import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";

Sentry.init({
    Vue,
    dsn: "https://bc87f340bc134919a898aff43f06fd0f@o294546.ingest.sentry.io/6088629",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "noibo.edutalk.edu.vn", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
    config: {id: process.env.VUE_APP_GOOGLE_ANALYTICS},
    appName: 'Edutalk Nội Bộ',
    pageTrackerScreenviewEnabled: true
}, router);

export default router;
